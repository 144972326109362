.video-container {
  display: flex;
  gap: 5ch;
  flex-direction: column;
  position: relative;
  top: 20ch;
  width: 70%;
  height: 115vh;
  margin: auto;
}

.video {
  display: flex;
  width: 100%;
  height: 100%;
  box-shadow: 1px 1px 20px black;
}

#videodownload {
  display: flex;
  position: relative;
  justify-content: center;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  border-radius: 100px !important;
  background-color: rgb(83, 10, 10) !important;
  color: rgb(233, 225, 225) !important;
  width: 10ch; /* Set a fixed width */
  padding: 1.9ch;
  font-size: 1em;
  letter-spacing: 1px;
  font-weight: 600;
  cursor: pointer;
}

#videodownload:hover {
  background-color: black !important;
}

@media screen and (max-width: 768px) {
  #videodownload {
    font-size: 0.8em !important;
  }
}

@media screen and (max-width: 480px) {
  #videodownload {
    font-size: 0.6em !important;
  }
}
